/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense } from 'react';
import PageLoader from 'app/components/core/preloader/page-preloader';
import Loader from 'app/components/core/preloader';
import { TitleAnchor } from 'app/components/core/title';
import { baseAdminPath } from 'app/constants/url/admin';
import { baseKidPath, forgotCredentialsPaths, kidSignInPaths } from 'app/constants/url/kid';
import {
  authPaths,
  basePath,
  booksPath,
  calculatorPath,
  checkersPath,
  checkoutSessionPaths,
  childPath,
  childrenPath,
  classesPath,
  classPath,
  completeProfilePath,
  configurationsPath,
  createClassPath,
  createLicensePath,
  digitoolsPath,
  documentsPath,
  emailVerificationPath,
  exercisesPath,
  fieldsPath,
  fixedNumberCardsPath,
  forgotPasswordPath,
  gradesPath,
  groupClassPaths,
  groupCreateClassPath,
  groupCreateKidPath,
  groupPath,
  groupsPath,
  invitesPath,
  joinPath,
  learningCulturesManagePath,
  learningCulturesPath,
  learningCulturesViewPath,
  learningLinesPath,
  maintenancePath,
  moneyPath,
  notepadPath,
  numberCardsPath,
  playingCardsPath,
  plusminTablePath,
  profilePath,
  questionBoxesPath,
  resetCredentialsPaths,
  settingsPaths,
  sharedPaths,
  signInPaths,
  signOutPath,
  signUpPaths,
  tableSquarePath,
  tasksPath,
  videosPath,
  whiteboardPath,
  stopwatchPath,
  measuringTapePath,
  numberStepperPath,
  conversionTablePath,
  clockPath,
  digitalClockPath,
  analogClockPath,
  timerClockPath,
  wordReaderPath,
  lineReaderPath,
  longhandArithmeticPath,
} from 'app/constants/url/shared';
import { useTokenData } from 'data/index';
import { ROLE } from 'data/enums';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from 'app/utils/protected/protected-route';
import { KidLayoutPreloader } from 'app/components/core/preloader/kid-layout-preloader';
import lazyWithRetries from 'app/utils/helpers/lazy-with-retries';
import { ActiveGroupBoundary } from 'app/utils/error-boundaries/active-group';
import { useTranslation } from 'react-i18next';
import { ROLE_NAME } from 'app/constants/data';
import { Header, Layout } from 'app/components/layout';
import ErrorBoundary from 'app/utils/error-boundaries';
import ErrorPage from 'app/components/shared/error-page';
import { PublicLayout } from 'app/components/public/layout';
import PublicRoutesWrapper from 'app/containers/public/public-routes-wrapper';
import { AdminSidebar } from 'app/components/layout/admin-sidebar';
import { GroupContainer } from 'app/containers/group';
import { CoachSidebar } from 'app/components/layout/coach-sidebar';

const AdminRoute = lazyWithRetries(() => import('../containers/admin'));
const SharedVideosRoute = lazyWithRetries(() => import('app/containers/shared/videos'));

const CreateKidPage = lazyWithRetries(() => import('app/pages/groups/[groupId]/create-kid/page'));
const ChildrenPage = lazyWithRetries(() => import('app/pages/children/page'));
const ChildRoute = lazyWithRetries(() => import('app/routing/shared/child'));

const ConfigurationsPage = lazyWithRetries(() => import('app/pages/configurations/page'));
const FieldsPage = lazyWithRetries(() => import('app/pages/fields/page'));
const GradesPage = lazyWithRetries(() => import('app/pages/grades/page'));
const CreateClassPage = lazyWithRetries(() => import('app/pages/classes/create/page'));

const DigitoolsPage = lazyWithRetries(() => import('app/pages/digitools/page'));
const TableSquarePage = lazyWithRetries(() => import('app/pages/digitools/table-square/page'));
const PlusminTablePage = lazyWithRetries(() => import('app/pages/digitools/plusmin-table/page'));
const PlayingCardsPage = lazyWithRetries(() => import('app/pages/digitools/playing-cards/page'));
const CalculatorPage = lazyWithRetries(() => import('app/pages/digitools/calculator/page'));
const CheckersPage = lazyWithRetries(() => import('app/pages/digitools/checkers/page'));
const MoneyPage = lazyWithRetries(() => import('app/pages/digitools/money/page'));
const NumberCardsPage = lazyWithRetries(() => import('app/pages/digitools/number-cards/page'));
const FixedNumberCardsPage = lazyWithRetries(() => import('app/pages/digitools/fixed-number-cards/page'));
const NotepadPage = lazyWithRetries(() => import('app/pages/digitools/notepad/page'));
const WhiteboardPage = lazyWithRetries(() => import('app/pages/digitools/whiteboard/page'));
const ClockPage = lazyWithRetries(() => import('app/pages/digitools/clock/page'));
const StopwatchPage = lazyWithRetries(() => import('app/pages/digitools/clock/stopwatch/page'));
const MeasuringTapePage = lazyWithRetries(() => import('app/pages/digitools/measuring-tape/page'));
const NumberStepperPage = lazyWithRetries(() => import('app/pages/digitools/number-stepper/page'));
const ConversionTablePage = lazyWithRetries(() => import('app/pages/digitools/conversion-table/page'));
const DigitalClockPage = lazyWithRetries(() => import('app/pages/digitools/clock/digital-clock/page'));
const AnalogClockPage = lazyWithRetries(() => import('app/pages/digitools/clock/analog-clock/page'));
const TimerClockPage = lazyWithRetries(() => import('app/pages/digitools/clock/timer-clock/page'));
const WordReaderPage = lazyWithRetries(() => import('app/pages/digitools/word-reader/page'));
const LineReaderPage = lazyWithRetries(() => import('app/pages/digitools/line-reader/page'));
const LonghandArithmeticPage = lazyWithRetries(() => import('app/pages/digitools/longhand-arithmetic/page'));

const FileManagementRoute = lazyWithRetries(() => import('app/containers/shared/files-management'));
const InvitesPage = lazyWithRetries(() => import('app/pages/invites/page'));
const LearningCulturesManageRoute = lazyWithRetries(() => import('app/routing/shared/learning-cultures/manage'));
const LearningCulturesViewRoute = lazyWithRetries(() => import('app/routing/shared/learning-cultures/view'));
const LearningCulturesContentRoute = lazyWithRetries(() => import('app/routing/shared/learning-cultures'));
const LibraryRoute = lazyWithRetries(() => import('app/containers/shared/library'));
const ProfileRoute = lazyWithRetries(() => import('app/containers/public/profile'));
const QuestionBoxesRoute = lazyWithRetries(() => import('app/routing/shared/question-boxes'));
const SettingsPage = lazyWithRetries(() => import('app/pages/settings/page'));
const LearningLinesRoute = lazyWithRetries(() => import('app/routing/shared/learning-lines'));
const ExercisesRoute = lazyWithRetries(() => import('app/routing/shared/exercises'));
const TasksPage = lazyWithRetries(() => import('app/pages/tasks/page'));
const NewTaskPage = lazyWithRetries(() => import('app/pages/tasks/new/page'));
const TaskStatisticsPage = lazyWithRetries(() => import('app/pages/tasks/[taskId]/statistics/page'));
const EditTaskPage = lazyWithRetries(() => import('app/pages/tasks/[taskId]/edit/page'));
const DuplicateTaskPage = lazyWithRetries(() => import('app/pages/tasks/[taskId]/duplicate/page'));

const KidSignInPage = lazyWithRetries(() => import('app/pages/kid/sign-in/page'));
const ForgotSecretIdentifierPage = lazyWithRetries(() => import('app/pages/kid/[forgot-secret-identifier]/page'));
const KidRoute = lazyWithRetries(() => import('app/routing/kid/kid-route'));

const HomePage = lazyWithRetries(() => import('app/pages/page'));

const SignInPage = lazyWithRetries(() => import('app/pages/sign-in/page'));
const SignUpPage = lazyWithRetries(() => import('app/pages/sign-up/page'));
const JoinPage = lazyWithRetries(() => import('app/pages/join/page'));
const AuthCallbackPage = lazyWithRetries(() => import('app/pages/auth/[provider]/callback/page'));
const SignOutPage = lazyWithRetries(() => import('app/pages/sign-out/page'));
const ForgotPasswordPage = lazyWithRetries(() => import('app/pages/forgot-password/page'));
const ResetCredentialsPage = lazyWithRetries(() => import('app/pages/reset-credentials/page'));
const EmailVerificationPage = lazyWithRetries(() => import('app/pages/email-verification/page'));
const CheckoutSessionPage = lazyWithRetries(() => import('app/pages/checkout-session/[status]/page'));
const MaintenancePage = lazyWithRetries(() => import('app/pages/maintenance/page'));
const CompleteProfilePage = lazyWithRetries(() => import('app/pages/complete-profile/page'));

const CreateGroupLicensePage = lazyWithRetries(() => import('app/containers/admin/groups/create-license'));
const CreateGroupClassPage = lazyWithRetries(() => import('app/pages/groups/[groupId]/classes/create/page'));
const GroupPage = lazyWithRetries(() => import('app/pages/groups/[groupId]/page'));
const GroupsPage = lazyWithRetries(() => import('app/pages/groups/page'));
/* Classes pages */
// Those are different views on the class.
// One from the group member perspective
const ClassesPage = lazyWithRetries(() => import('app/pages/classes/page'));
const ClassPage = lazyWithRetries(() => import('app/pages/classes/[classId]/page'));
// Another from the admin pov
const GroupClassPage = lazyWithRetries(() => import('app/pages/groups/[groupId]/classes/[classId]/page'));
/* Classes pages */

const sidebarsMapper = {
  [ROLE.ADMIN]: <AdminSidebar />,
  [ROLE.COACH]: <CoachSidebar />,
};

export const logoPaths = {
  [ROLE.ADMIN]: learningLinesPath,
  [ROLE.COACH]: childrenPath,
};

const Router = () => {
  const tokenData = useTokenData();
  const role = tokenData?.role;

  const { t } = useTranslation();

  return (
    <>
      <TitleAnchor tab="cleverKids" />
      <ActiveGroupBoundary>
        <Switch>
          <Route path={baseKidPath}>
            <Suspense fallback={<KidLayoutPreloader />}>
              <TitleAnchor tab="kid" />
              <Switch>
                <Route path={kidSignInPaths.index}>
                  <TitleAnchor tab="signIn" />
                  <KidSignInPage />
                </Route>
                <Route path={forgotCredentialsPaths.password}>
                  <ForgotSecretIdentifierPage />
                </Route>
                <Route path={forgotCredentialsPaths.passcode}>
                  <ForgotSecretIdentifierPage />
                </Route>
                <ProtectedRoute path={baseKidPath} allowedRoles={[ROLE.KID]} redirectPath={kidSignInPaths.index}>
                  <KidRoute />
                </ProtectedRoute>
              </Switch>
            </Suspense>
          </Route>

          <ProtectedRoute path={videosPath} allowedRoles={[ROLE.ADMIN, ROLE.COACH, ROLE.KID]}>
            <Suspense fallback={<Loader />}>
              <SharedVideosRoute />
            </Suspense>
          </ProtectedRoute>

          <ProtectedRoute allowedRoles={[ROLE.COACH]} path={joinPath}>
            <PublicLayout>
              <JoinPage />
            </PublicLayout>
          </ProtectedRoute>

          <ProtectedRoute path={sharedPaths} allowedRoles={[ROLE.ADMIN, ROLE.COACH]}>
            <TitleAnchor tab={`${t(ROLE_NAME[role as ROLE])}`} />
            <Layout
              key={role}
              header={<Header profilePath={profilePath} invitesPath={invitesPath} />}
              sidebar={sidebarsMapper[role as ROLE]}
              content={
                <Suspense fallback={<Loader />}>
                  <ErrorBoundary Component={ErrorPage}>
                    <Switch>
                      <ProtectedRoute path={configurationsPath} allowedRoles={[ROLE.ADMIN]}>
                        <ConfigurationsPage />
                      </ProtectedRoute>
                      <ProtectedRoute path={fieldsPath} allowedRoles={[ROLE.ADMIN]}>
                        <FieldsPage />
                      </ProtectedRoute>
                      <ProtectedRoute path={gradesPath} allowedRoles={[ROLE.ADMIN]}>
                        <GradesPage />
                      </ProtectedRoute>

                      <Route path={invitesPath}>
                        <TitleAnchor tab="invites" />
                        <InvitesPage />
                      </Route>

                      <Route path={profilePath}>
                        <TitleAnchor tab={t('profile')} />

                        <ProfileRoute />
                      </Route>

                      <Route path={tasksPath.index}>
                        <TitleAnchor tab={t('tasks')} />
                        <Switch>
                          <Route path={tasksPath.new}>
                            <TitleAnchor tab={t('newTask')} />
                            <NewTaskPage />
                          </Route>
                          <Route path={tasksPath.duplicate(':taskId')}>
                            <DuplicateTaskPage />
                          </Route>

                          <Route path={tasksPath.edit(':taskId')}>
                            <EditTaskPage />
                          </Route>

                          <Route path={tasksPath.statistics(':taskId')}>
                            <TaskStatisticsPage />
                          </Route>

                          <Route exact path={tasksPath.index}>
                            <TasksPage />
                          </Route>
                        </Switch>
                      </Route>

                      <ProtectedRoute path={digitoolsPath} allowedRoles={[ROLE.ADMIN, ROLE.COACH]}>
                        <Switch>
                          <Route path={calculatorPath}>
                            <CalculatorPage />
                          </Route>

                          <Route path={checkersPath}>
                            <CheckersPage />
                          </Route>

                          <Route path={fixedNumberCardsPath}>
                            <FixedNumberCardsPage />
                          </Route>

                          <Route path={moneyPath}>
                            <MoneyPage />
                          </Route>

                          <Route path={notepadPath}>
                            <NotepadPage />
                          </Route>

                          <Route path={numberCardsPath}>
                            <NumberCardsPage />
                          </Route>

                          <Route path={playingCardsPath}>
                            <PlayingCardsPage />
                          </Route>

                          <Route path={plusminTablePath}>
                            <PlusminTablePage />
                          </Route>

                          <Route path={tableSquarePath}>
                            <TableSquarePage />
                          </Route>

                          <Route path={whiteboardPath}>
                            <WhiteboardPage />
                          </Route>

                          <Route path={measuringTapePath}>
                            <MeasuringTapePage />
                          </Route>

                          <Route path={numberStepperPath}>
                            <NumberStepperPage />
                          </Route>

                          <Route path={conversionTablePath}>
                            <ConversionTablePage />
                          </Route>

                          <Route path={measuringTapePath}>
                            <MeasuringTapePage />
                          </Route>

                          <Route path={longhandArithmeticPath}>
                            <LonghandArithmeticPage />
                          </Route>

                          <Route path={digitalClockPath}>
                            <DigitalClockPage />
                          </Route>

                          <Route path={analogClockPath}>
                            <AnalogClockPage />
                          </Route>

                          <Route path={timerClockPath}>
                            <TimerClockPage />
                          </Route>

                          <Route path={wordReaderPath}>
                            <WordReaderPage />
                          </Route>

                          <Route path={lineReaderPath}>
                            <LineReaderPage />
                          </Route>

                          <Route path={stopwatchPath}>
                            <StopwatchPage />
                          </Route>

                          <Route path={clockPath}>
                            <ClockPage />
                          </Route>

                          <Route path={digitoolsPath}>
                            <DigitoolsPage />
                          </Route>
                        </Switch>
                      </ProtectedRoute>

                      <Route path={documentsPath}>
                        <FileManagementRoute />
                      </Route>

                      <Route path={booksPath}>
                        <LibraryRoute />
                      </Route>

                      <Route path={learningCulturesViewPath}>
                        <LearningCulturesViewRoute />
                      </Route>
                      <ProtectedRoute path={learningCulturesManagePath} allowedRoles={[ROLE.ADMIN]}>
                        <LearningCulturesManageRoute />
                      </ProtectedRoute>

                      <Route path={learningCulturesPath}>
                        <LearningCulturesContentRoute />
                      </Route>
                      <Route path={learningLinesPath}>
                        <LearningLinesRoute />
                      </Route>
                      <Route path={exercisesPath}>
                        <ExercisesRoute />
                      </Route>

                      <Route path={groupsPath}>
                        <Switch>
                          <Route path={groupCreateKidPath(':groupId')}>
                            <GroupContainer>
                              <TitleAnchor tab={t('createKid')} />
                              <CreateKidPage />
                            </GroupContainer>
                          </Route>

                          <Route path={groupCreateClassPath(':groupId')}>
                            <GroupContainer>
                              <CreateGroupClassPage />
                            </GroupContainer>
                          </Route>

                          <Route path={createLicensePath(':groupId')}>
                            <CreateGroupLicensePage />
                          </Route>

                          <Route path={groupClassPaths.createKid(':groupId', ':classId')}>
                            <TitleAnchor tab={t('createKid')} />
                            <CreateKidPage />
                          </Route>

                          <Route path={groupClassPaths.index(':groupId', ':classId')}>
                            <GroupClassPage />
                          </Route>

                          <Route path={groupPath(':groupId')}>
                            <GroupContainer>
                              <GroupPage />
                            </GroupContainer>
                          </Route>

                          <Route path={groupsPath}>
                            <TitleAnchor tab={t('teams')} />
                            <GroupsPage />
                          </Route>
                        </Switch>
                      </Route>

                      <Route path={childrenPath}>
                        <TitleAnchor tab={t('children')} />
                        <GroupContainer>
                          <Switch>
                            <Route path={childPath(':kidId')}>
                              <ChildRoute />
                            </Route>

                            <Route path={childrenPath}>
                              <ChildrenPage />
                            </Route>
                          </Switch>
                        </GroupContainer>
                      </Route>

                      <Route path={classesPath}>
                        <GroupContainer>
                          <TitleAnchor tab={t('classes')} />
                          <Switch>
                            <Route path={createClassPath}>
                              <CreateClassPage />
                            </Route>
                            <Route path={classPath(':classId')}>
                              <ClassPage />
                            </Route>
                            <Route path={classesPath}>
                              <ClassesPage />
                            </Route>
                          </Switch>
                        </GroupContainer>
                      </Route>

                      <ProtectedRoute path={settingsPaths.index} allowedRoles={[ROLE.COACH]}>
                        <GroupContainer>
                          <SettingsPage />
                        </GroupContainer>
                      </ProtectedRoute>

                      <Route path={questionBoxesPath}>
                        <QuestionBoxesRoute />
                      </Route>
                    </Switch>
                  </ErrorBoundary>
                </Suspense>
              }
              logoPath={logoPaths[role as ROLE]}
            />
          </ProtectedRoute>

          <ProtectedRoute allowedRoles={[ROLE.ADMIN]} path={baseAdminPath}>
            <Suspense fallback={<Loader />}>
              <AdminRoute />
            </Suspense>
          </ProtectedRoute>

          <Route path={authPaths.callback}>
            <AuthCallbackPage />
          </Route>

          <Route exact path={signOutPath}>
            <SignOutPage />
          </Route>

          <Route path={basePath}>
            <Route>
              <Suspense fallback={<PageLoader />}>
                <Route exact path={maintenancePath}>
                  <MaintenancePage />
                </Route>
                <PublicLayout>
                  <PublicRoutesWrapper>
                    <Route exact path={basePath}>
                      <HomePage />
                    </Route>
                    <Route exact path={signInPaths.index}>
                      <SignInPage />
                    </Route>
                    <Route exact path={completeProfilePath}>
                      <CompleteProfilePage />
                    </Route>
                    <Route path={signUpPaths.index}>
                      <SignUpPage />
                    </Route>
                    <Route path={emailVerificationPath}>
                      <EmailVerificationPage />
                    </Route>
                    <Route exact path={forgotPasswordPath}>
                      <ForgotPasswordPage />
                    </Route>
                    <Route path={resetCredentialsPaths.index}>
                      <ResetCredentialsPage />
                    </Route>
                    <Route path={checkoutSessionPaths.status(':status')}>
                      <CheckoutSessionPage />
                    </Route>
                  </PublicRoutesWrapper>
                </PublicLayout>
              </Suspense>
            </Route>
          </Route>

          <Route path="*" render={() => <Redirect to={basePath} />} />
        </Switch>
      </ActiveGroupBoundary>
    </>
  );
};
export default Router;
